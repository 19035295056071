import { RouteRecordRaw } from 'vue-router';
export const routes: Array<RouteRecordRaw> = [
  {
    path: '/prodComprehensiveDaily',
    name: 'ProdComprehensiveDaily',
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/ComprehensiveDaily/ComprehensiveDaily.vue'
      ),
    meta: {
      title: '运营-综合日报'
    }
  },
  {
    path: '/blueSkyBudget',
    name: 'BudgetReport',
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/BudgetReport/BudgetReport.vue'
      ),
    meta: {
      title: '运营-预算分析'
    }
  },
  {
    path: '/energyManagement',
    name: 'EnergyManagement',
    meta: {
      title: '运营-能源管理'
    },
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/EnergyManagement/EnergyManagement.vue'
      )
  },
  {
    path: '/energyDetail',
    name: 'EnergyDetail',
    meta: {
      title: '运营-能源消耗'
    },
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/EnergyManagement/EnergyDetail.vue'
      )
  },
  {
    path: '/dailyInspection',
    name: 'DailyInspection',
    meta: {
      title: '运营-设备日检'
    },
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/DailyInspection/DailyInspection.vue'
      )
  },
  {
    path: '/dailyInspectionDetail',
    name: 'DailyInspectionDetail',
    meta: {
      title: '运营-设备日检-详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "operate" */ '../views/Operate/DailyInspection/DailyInspectionDetail.vue'
      )
  },
  {
    path: '/attendanceAnalysis',
    name: 'AttendanceAnalysis',
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Work/AttendanceAnalysis/AttendanceAnalysis.vue'
      ),
    meta: {
      title: '办公-考勤分析'
    }
  },
  {
    path: '/absentPersonnel',
    name: 'AbsentPersonnel',
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Work/AttendanceAnalysis/AbsentPersonnel.vue'
      ),
    meta: {
      title: '未出勤人员'
    }
  },
  {
    path: '/communication',
    name: 'Communication',
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Communication/Index/Index.vue'
      ),
    meta: {
      title: '办公-通讯录'
    }
  },
  {
    path: '/PersonalDetail',
    name: 'PersonalDetail',
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Communication/PersonalDetail/PersonalDetail.vue'
      ),
    meta: {
      title: '办公-个人详情'
    }
  },
  {
    path: '/myDepartment',
    name: 'MyDepartment',
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Communication/MyDepartment/MyDepartment.vue'
      ),
    meta: {
      title: '办公-我的部门'
    }
  },
  {
    path: '/externalContacts',
    name: 'ExternalContacts',
    component: () =>
      import(
        /* webpackChunkName: "work" */ '../views/Communication/ExternalContacts/ExternalContacts.vue'
      ),
    meta: {
      title: '办公-外部联系人'
    }
  },
  {
    path: '/productionLog',
    name: 'ProReportAggregate',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/ProReportAggregate/ProReportAggregate.vue'
      ),
    meta: {
      title: '生产报表'
    }
  },
  {
    path: '/yieldCorrection',
    name: 'YieldCorrection',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/YieldCorrection/YieldCorrection.vue'
      ),
    meta: {
      title: '产量修正'
    }
  },
  {
    path: '/collectionCorrection',
    name: 'collectionCorrection',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Operate/ComprehensiveDaily/collectionCorrection.vue'
      ),
    meta: {
      title: '收款修正'
    }
  },
  {
    path: '/modifyRecords',
    name: 'ModifyRecords',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/YieldCorrection/ModifyRecords.vue'
      ),
    meta: {
      title: '修改记录'
    }
  },
  {
    path: '/shutdownReason',
    name: 'ShutdownReason',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/ProReportAggregate/ShutdownReason.vue'
      ),
    meta: {
      title: '停机原因'
    }
  },
  {
    path: '/stockAggregateLevel',
    name: 'StockAggregateLevel',
    meta: {
      title: '成品料位'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/AggregateLevel/AggregateLevel.vue'
      )
  },
  {
    path: '/stockMonitor',
    name: 'StockMonitor',
    meta: {
      title: '生产-监控'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/Monitor/Monitor.vue'
      )
  },
  {
    path: '/proReportShangTong',
    name: 'ProReportShangTong',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/ProReportShangTong/ProReportShangTong.vue'
      ),
    meta: {
      title: '生产-商砼报表'
    }
  },
  {
    path: '/proReportMortar',
    name: 'ProReportMortar',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/ProReportMortar/ProReportMortar.vue'
      ),
    meta: {
      title: '生产-砂浆报表'
    }
  },
  {
    path: '/stoneSaleRank',
    name: 'StoneSaleRank',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/StoneSaleRank/StoneSaleRank.vue'
      ),
    meta: {
      title: '物料销售排行'
    }
  },
  {
    path: '/proEquipmentLive',
    name: 'ProEquipmentLive',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/EquipmentLive.vue'
      ),
    meta: {
      title: '生产-设备状态'
    },
    redirect: '/proEquipmentLive/list',
    children: [
      {
        path: 'list',
        name: 'ProEquipmentLiveList',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/List.vue'
          ),
        meta: {
          title: '生产-设备状态'
        }
      },
      {
        path: 'detail',
        name: 'ProEquipmentLiveDetail',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/Detail.vue'
          ),
        meta: {
          title: '设备状态详情'
        }
      }
    ]
  },
  {
    path: '/drawings',
    name: 'Drawings',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/Drawings.vue'
      ),
    meta: {
      title: '电气图纸'
    }
  },
  {
    path: '/MaintenanceList',
    name: 'MaintenanceList',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/Maintenance.vue'
      ),
    meta: {
      title: '设备保养记录'
    }
  },
  {
    path: '/RepairList',
    name: 'RepairList',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/Repair.vue'
      ),
    meta: {
      title: '设备维修记录'
    }
  },
  {
    path: '/PointRepairList',
    name: 'PointRepairList',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/PointRepair.vue'
      ),
    meta: {
      title: '点检记录'
    }
  },
  {
    path: '/usings',
    name: 'Usings',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/Production/EquipmentLive/Usings.vue'
      ),
    meta: {
      title: '使用说明'
    }
  },
  {
    path: '/warehouseSpareParts',
    name: 'WarehouseSpareParts',
    meta: {
      title: '仓库-实时库存'
    },
    component: () =>
      import(
        /* webpackChunkName: "storeHouse" */ '../views/Warehouse/SpareParts/SpareParts.vue'
      )
  },
  {
    path: '/warehouseRequisition',
    name: 'WarehouseRequisition',
    meta: {
      title: '仓库-领用情况'
    },
    component: () =>
      import(
        /* webpackChunkName: "storeHouse" */ '../views/Warehouse/Requisition/Requisition.vue'
      )
  },
  // {
  //   path: '/stockAggregate',
  //   name: 'StockAggregate',
  //   meta: {
  //     title: '库存-骨料成品'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "storeHouse" */ '../views/Production/Stock/Aggregate/Aggregate.vue'
  //     )
  // },
  // {
  //   path: '/stockShangTong',
  //   name: 'StockShangTong',
  //   meta: {
  //     title: '库存-商砼原料'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "storeHouse" */ '../views/Production/Stock/ShangTong/ShangTong.vue'
  //     )
  // },
  // {
  //   path: '/stockMortar',
  //   name: 'StockMortar',
  //   meta: {
  //     title: '库存-砂浆原料'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "storeHouse" */ '../views/Production/Stock/Mortar/Mortar.vue'
  //     )
  // },
  // {
  //   path: '/stockShangTongLevel',
  //   name: 'StockShangTongLevel',
  //   meta: {
  //     title: '库存-商砼料位'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "storeHouse" */ '../views/Production/Stock/ShangTongLevel/ShangTongLevel.vue'
  //     )
  // },
  // {
  //   path: '/stockMortarLevel',
  //   name: 'StockMortarLevel',
  //   meta: {
  //     title: '库存-砂浆料位'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "storeHouse" */ '../views/Production/Stock/MortarLevel/MortarLevel.vue'
  //     )
  // },
  {
    path: '/productionData',
    name: 'ProductionData',
    meta: {
      title: '管家报表-生产数据'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/ProductionData/ProductionData.vue'
      )
  },
  {
    path: '/salesAggregateDailyReport',
    name: 'SalesAggregateDailyReport',
    meta: {
      title: '销售-骨料报表'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ReportAggregate/ReportAggregate.vue'
      )
  },
  {
    path: '/aggregateSalesPrice',
    name: 'SalePrice',
    meta: {
      title: '销售-销售价格'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/SalePrice/SalePrice.vue'
      )
  },
  {
    path: '/saleMortarDailyReport',
    name: 'SaleMortarDailyReport',
    meta: {
      title: '销售-砂浆报表'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ReportMortar/ReportMortar.vue'
      )
  },
  {
    path: '/productAndSale',
    name: 'ProductAndSale',
    meta: {
      title: '产销对比'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ProductAndSale/Index.vue'
      )
  },
  {
    path: '/customerAnalysisData',
    name: 'CustomerAnalysisData',
    meta: {
      title: '客户经营分析'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ProductAndSale/Count.vue'
      )
  },
  {
    path: '/saleShangTongDailyReport',
    name: 'SaleShangTongDailyReport',
    meta: {
      title: '销售-商砼报表'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ReportShangTong/ReportShangTong.vue'
      )
  },
  {
    path: '/planDetail',
    name: 'PlanDetail',
    meta: {
      title: '销售-商砼计划详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ReportShangTong/PlanDetail.vue'
      )
  },
  {
    path: '/saleReportDetail',
    name: 'SaleReportDetail',
    meta: {
      title: '销售明细'
    },
    component: () =>
      import(
        /* webpackChunkName: "sale" */ '../views/Sale/ReportDetail/ReportDetail.vue'
      )
  },
  {
    path: '/purchaseShangTongDailyReport',
    name: 'purchaseShangTongDailyReport',
    meta: {
      title: '采购-商砼报表'
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/ReportShangTong/ReportShangTong.vue'
      )
  },
  {
    path: '/purchaseMortarDailyReport',
    name: 'PurchaseMortarDailyReport',
    meta: {
      title: '采购-砂浆报表'
    },
    props: {
      type: 0
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/ReportMortar/ReportMortar.vue'
      )
  },
  {
    path: '/purchaseAggregateDailyReport',
    name: 'PurchaseAggregateDailyReport',
    meta: {
      title: '采购-骨料原石报表'
    },
    props: {
      type: 0
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/ReportAggregate/ReportAggregate.vue'
      )
  },
  {
    path: '/purchaseSpareParts',
    name: 'PurchaseSpareParts',
    meta: {
      title: '采购-备品备件'
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/SpareParts/SpareParts.vue'
      )
  },
  {
    path: '/sparePartsDetail',
    name: 'SpartPartsDetail',
    meta: {
      title: '采购-备品备件详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/SpareParts/Detail.vue'
      )
  },
  {
    path: '/purchaseReportDetail',
    name: 'PurchaseReportDetail',
    meta: {
      title: '采购明细'
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase" */ '../views/Purchase/ReportDetail/ReportDetail.vue'
      )
  },

  {
    path: '/applyRequisition',
    name: 'ApplyRequisition',
    meta: {
      title: '生产领用',
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/ApplyRequisition/Index.vue'
      )
  },
  {
    path: '/selectMaterial',
    name: 'SelectMaterial',
    meta: {
      title: '选择物料'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/SelectMaterial/Index.vue'
      )
  },
  {
    path: '/selectWarehouse',
    name: 'SelectWarehouse',
    meta: {
      title: '选择仓库'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/SelectWarehouse/Index.vue'
      )
  },
  {
    path: '/selectStaff',
    name: 'SelectStaff',
    meta: {
      title: '选择仓库'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/SelectStaff/Index.vue'
      )
  },
  {
    path: '/requisitionList',
    name: 'RequisitionList',
    meta: {
      title: '生产领用明细'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/RequisitionList/Index.vue'
      )
  },
  {
    path: '/requisitionMaterialList',
    name: 'RequisitionMaterialList',
    meta: {
      title: '领用物料'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/RequisitionMaterialList/Index.vue'
      )
  },
  {
    path: '/materialPositionInfo',
    name: 'MaterialPositionInfo',
    meta: {
      title: '货位信息'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/ProRequisition/MaterialPositionInfo/Index.vue'
      )
  },
  {
    path: '/rechargeConsumptionCount',
    name: 'RechargeConsumptionCount',
    meta: {
      title: '充值消费-统计'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/Sale/Count/Index.vue'
      )
  },
  {
    path: '/financeBudget',
    name: 'FinanceBudget',
    meta: {
      title: '预算录入'
    },
    component: () =>
      import(
        /* webpackChunkName: "proRequisition" */ '../views/Finance/Budget/Index.vue'
      )
  },
  {
    path: '/patrolScheme',
    name: 'PatrolScheme',
    meta: {
      title: '',
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolScheme/index.vue'
      )
  },
  {
    path: '/addPatrolScheme',
    name: 'AddPatrolScheme',
    meta: {
      title: '添加巡检计划'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolScheme/addPatrolScheme.vue'
      )
  },

  {
    path: '/patrolScheme/detail',
    name: 'PatrolSchemeDetail',
    meta: {
      title: '巡检详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolScheme/Detail.vue'
      )
  },
  {
    path: '/patrolGuard',
    name: 'PatrolGuard',
    meta: {
      title: '巡保任务'
    },
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/PatrolGuard/index.vue')
  },
  {
    path: '/addPatrolGuard',
    name: 'AddPatrolGuard',
    meta: {
      title: '添加保养内容'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolGuard/addPatrolGuard.vue'
      )
  },
  {
    path: '/patrolGuard/detail',
    name: 'PatrolGuardDetail',
    meta: {
      title: '详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolGuard/Detail.vue'
      )
  },
  {
    path: '/patrolGuard/complete',
    name: 'PatrolGuardComplete',
    meta: {
      title: '添加保养内容'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolGuard/Complete.vue'
      )
  },
  {
    path: '/deviceInfo',
    name: 'DeviceInfo',
    meta: {
      title: '设备列表',
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/DeviceInfo/index.vue')
  },
  {
    path: '/deviceInfo/detail',
    name: 'DeviceInfoDetail',
    meta: {
      title: '设备详情'
    },
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/DeviceInfo/Detail.vue')
  },
  {
    path: '/toPatrolScheme/detail',
    name: 'ToPatrolSchemeDetail',
    meta: {
      title: '巡检任务详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolScheme/fromDeviceInfo.vue'
      )
  },
  {
    path: '/toPatrolGuard/detail',
    name: 'ToPatrolGuardDetail',
    meta: {
      title: '保养任务详情'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/PatrolGuard/fromDeviceInfo.vue'
      )
  },
  {
    path: '/deviceAlarm',
    name: 'DeviceAlarm',
    meta: {
      title: '设备告警',
      keepAlive: true
    },
    component: () =>
      import(/* webpackChunkName: "product" */ '../views/DeviceAlarm/index.vue')
  },
  {
    path: '/deviceAlarm/detail',
    name: 'DeviceAlarmDetail',
    meta: {
      title: '设备告警详情',
      keepAlive: true
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/DeviceAlarm/Detail.vue'
      )
  },
  {
    path: '/deviceAlarm/addAlarmRule',
    name: 'AddAlarmRule',
    meta: {
      title: '告警规则'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/DeviceAlarm/AddAlarmRule.vue'
      )
  },
  {
    path: '/deviceAlarm/handleAlarm',
    name: 'HandleAlarm',
    meta: {
      title: '处理告警'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/DeviceAlarm/HandleAlarm.vue'
      )
  },
  {
    path: '/taskHistory',
    name: 'TaskHistory',
    meta: {
      title: '任务历史'
    },
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/DeviceInfo/history.vue'
      )
  },
  {
    path: '/vibrationEquipment',
    name: 'VibrationEquipment',
    component: () =>
      import(
        /* webpackChunkName: "product" */ '../views/VibrationEquipment/index.vue'
      ),
    meta: {
      title: '厂区设备'
    },
    redirect: '/vibrationEquipment/list',
    children: [
      {
        path: 'list',
        name: 'VibrationEquipmentList',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '../views/VibrationEquipment/List.vue'
          ),
        meta: {
          title: '厂区设备'
        }
      },
      {
        path: 'curve',
        name: 'Curve',
        component: () =>
          import(
            /* webpackChunkName: "product" */ '../views/VibrationEquipment/Detail.vue'
          ),
        meta: {
          title: '砂机震动与电流曲线'
        }
      }
    ]
  }

  // {
  //   path: '/guliaoSellDay1',
  //   name: 'GuliaoSellDay1',
  //   meta: {
  //     title: '骨料销售价格表格版'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "sale" */ '../views/table/GuliaoSellDay1.vue')
  // },
  // {
  //   path: '/guliaoSellDay2',
  //   name: 'GuliaoSellDay2',
  //   meta: {
  //     title: '骨料销售价格表格版'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "sale" */ '../views/table/GuliaoSellDay2.vue')
  // },
  // {
  //   path: '/guliaoSellDay',
  //   name: 'GuliaoSellDay',
  //   meta: {
  //     title: '销售-骨料日报'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "sell" */ '../views/table/GuliaoSellDay.vue')
  // },

  // {
  //   path: '/guliaoSellWeek',
  //   name: 'GuliaoSellWeek',
  //   meta: {
  //     title: '销售-骨料周报'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "sell" */ '../views/table/GuliaoSellWeek.vue')
  // },
  // {
  //   path: '/guliaoSellMonth',
  //   name: 'GuliaoSellMonth',
  //   meta: {
  //     title: '销售-骨料月报'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "sell" */ '../views/table/GuliaoSellMonth.vue'
  //     )
  // },
  // {
  //   path: '/sandMachineHour',
  //   name: 'SandMachineHour',
  //   meta: {
  //     title: '骨料生产日报-年报'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "sale" */ '../views/table/SandMachineHour.vue'
  //     )
  // },
  // {
  //   path: '/guliaoBuyDay',
  //   name: 'GuliaoBuyDay',
  //   meta: {
  //     title: '采购-骨料日报'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "buy" */ '../views/table/GuliaoBuyDay.vue')
  // }
  // {
  //   path: '/guliaoBuyWeek',
  //   name: 'GuliaoBuyWeek',
  //   meta: {
  //     title: '采购-骨料周报'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "buy" */ '../views/table/GuliaoBuyWeek.vue')
  // },
  // {
  //   path: '/guliaoBuyMonth',
  //   name: 'GuliaoBuyMonth',
  //   meta: {
  //     title: '采购-骨料月报'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "buy" */ '../views/table/GuliaoBuyMonth.vue')
  // },
  // {
  //   path: '/shajiangBuyDay',
  //   name: 'ShajiangBuyDay',
  //   meta: {
  //     title: '采购-砂浆日报'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "buy" */ '../views/table/ShajiangBuyDay.vue')
  // },
  // {
  //   path: '/shajiangBuyMonth',
  //   name: 'ShajiangBuyMonth',
  //   meta: {
  //     title: '采购-砂浆月报'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "buy" */ '../views/table/ShajiangBuyMonth.vue'
  //     )
  // },
  // {
  //   path: '/shajiangBuyWeek',
  //   name: 'ShajiangBuyWeek',
  //   meta: {
  //     title: '采购-砂浆周报'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "buy" */ '../views/table/ShajiangBuyWeek.vue')
  // },

  // {
  //   path: '/shangtongBuyDay',
  //   name: 'ShangtongBuyDay',
  //   meta: {
  //     title: '采购-商砼日报'
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "buy" */ '../views/table/ShangtongBuyDay.vue')
  // },
  // {
  //   path: '/shangtongBuyMonth',
  //   name: 'ShangtongBuyMonth',
  //   meta: {
  //     title: '采购-商砼月报'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "buy" */ '../views/table/ShangtongBuyMonth.vue'
  //     )
  // },
  // {
  //   path: '/shangtongBuyWeek',
  //   name: 'ShangtongBuyWeek',
  //   meta: {
  //     title: '采购-商砼周报'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "buy" */ '../views/table/ShangtongBuyWeek.vue'
  //     )
  // },
  // {
  //   path: '/shajiangSellDay',
  //   name: 'ShajiangSellDay',
  //   meta: {
  //     title: '销售-砂浆日报'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "sell" */ '../views/table/ShajiangSellDay.vue'
  //     )
  // },
  // {
  //   path: '/shajiangSellWeek',
  //   name: 'ShajiangSellWeek',
  //   meta: {
  //     title: '销售-砂浆周报'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "sell" */ '../views/table/ShajiangSellWeek.vue'
  //     )
  // },
  // {
  //   path: '/shajiangSellMonth',
  //   name: 'ShajiangSellMonth',
  //   meta: {
  //     title: '销售-砂浆月报'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "sell" */ '../views/table/ShajiangSellMonth.vue'
  //     )
  // },
  // {
  //   path: '/shangtongSellDay',
  //   name: 'ShangtongSellDay',
  //   meta: {
  //     title: '销售-商砼日报'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "sell" */ '../views/table/ShangtongSellDay.vue'
  //     )
  // },
  // {
  //   path: '/shangtongSellWeek',
  //   name: 'ShangtongSellWeek',
  //   meta: {
  //     title: '销售-商砼周报'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "sell" */ '../views/table/ShangtongSellWeek.vue'
  //     )
  // },
  // {
  //   path: '/shangtongSellMonth',
  //   name: 'ShangtongSellMonth',
  //   meta: {
  //     title: '销售-商砼月报'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "sell" */ '../views/table/ShangtongSellMonth.vue'
  //     )
  // },
  // {
  //   path: '/sellPrice',
  //   name: 'SellPrice',
  //   meta: {
  //     title: '销售-料类价格'
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "sell" */ '../views/table/GuliaoSellPrice.vue'
  //     )
  // }
];
