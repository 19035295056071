import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Nothing',
  props: {
    text: {
      type: String,
      default: '暂无数据'
    }
  },
  setup: function setup(props, ctx) {
    return {};
  }
});