import { defineComponent, watch } from 'vue';
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import router from './router';
export default defineComponent({
  components: {
    ElConfigProvider: ElConfigProvider
  },
  setup: function setup() {
    var statusBarHeight = 0;
    watch(function () {
      return router.options;
    }, function (options) {
      console.log(options, 'op');
    }, {
      immediate: true
    });
    function getStatusBarHeight() {
      if (window.visualViewport) {
        statusBarHeight = window.visualViewport.offsetTop;
      } else if (window.screen && window.matchMedia('(display-cutout: constant)').matches) {
        var devicePixelRatio = window.devicePixelRatio;
        statusBarHeight = Math.round(24 * devicePixelRatio); // 这里的24需要根据设备实际情况进行调整
      }
      return statusBarHeight;
    }
    getStatusBarHeight();
    // const statusBarHeight = getStatusBarHeight();
    // window.localStorage.setItem('statusBarHeight', `${statusBarHeight}`);
    return {
      locale: zhCn,
      statusBarHeight: statusBarHeight
    };
  }
});