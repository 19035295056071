import router from '@/router'
import dayjs from 'dayjs'
import { getCurrentInstance } from 'vue'

//路由跳转
export const useToPage = () => {
  return (path: string) => router.push(path)
}

//退出
export const useToBack = () => {
  return () => router.back()
}

//日期格式化
export const useDateFormat = () => {
  return (date = new Date(), rule = 'YYYY-MM-DD HH:mm:ss'): string =>
    dayjs(date).format(rule)
}

export const useEmptyStringFormat = () => {
  const curInstance = getCurrentInstance()
  const emptyStringFormat =
    curInstance?.appContext.config.globalProperties.$emptyStringFormat

  return emptyStringFormat
}

//获取当前日期为当前的第多少周
export const getYearWeek = (endDate: Date): string => {
  const startDate = new Date(endDate.getFullYear(), 0, 1)
  let endWeek = endDate.getDay()
  if (endWeek == 0) endWeek = 7
  let startWeek = startDate.getDay()
  if (startWeek == 0) startWeek = 7

  const millisDiff = endDate.getTime() - startDate.getTime()
  const dayDiff = Math.floor(
    (millisDiff + (startWeek - endWeek) * (24 * 60 * 60 * 1000)) / 86400000
  )
  return endDate.getFullYear() + '年/' + (Math.ceil(dayDiff / 7) + 1) + '周'
}
