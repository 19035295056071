import { App, Plugin } from 'vue'
import registerVant from './register-vant'
// import registerElementPlus from './register-element-plus'
import registerProperties from './register-properties'
import registerComponent from './register-component'
import directiveNumberInput from './number-input'

const plugins: Plugin[] = [
  registerVant,
  // registerElementPlus,
  registerProperties,
  registerComponent,
  directiveNumberInput
]

export function globalRegister(app: App): void {
  for (const plugin of plugins) {
    app.use(plugin)
  }
}
