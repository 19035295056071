import { Component, Plugin, App } from 'vue'
import CButton from 'components/CButton.vue'
import CPageHeader from 'components/CPageHeader.vue'
import CIcon from 'components/CIcon.vue'
import CTitle from 'components/CTitle.vue'
import CDateSelect from '@/components/CDateSelect.vue'
import CDateSelect1 from '@/components/CDateSelect1.vue'
import CDateTimeRangeSelect from 'components/CDateTimeRangeSelect.vue'
import CColumn from 'components/chart/CColumn.vue'
import CColumn1 from 'components/chart/CColumn1.vue'
import CDateHeaderWithBtn from 'components/CDateHeaderWithBtn.vue'
import CDateHeader from 'components/CDateHeader.vue'
import CNothing from 'components/CNothing.vue'
import CTypeTab from 'components/CTypeTab.vue'
import CNoData from 'components/CNoData.vue'

// ["CIcon", CIcon], //[key, value]
const components: Map<string, Component> = new Map([
  ['CButton', CButton as Component],
  ['CIcon', CIcon as Component],
  ['CPageHeader', CPageHeader as Component],
  ['CTitle', CTitle as Component],
  ['CDateSelect', CDateSelect as Component],
  ['CDateSelect1', CDateSelect1 as Component],
  ['CDateTimeRangeSelect', CDateTimeRangeSelect as Component],
  ['CColumn', CColumn as Component],
  ['CColumn1', CColumn1 as Component],
  ['CDateHeaderWithBtn', CDateHeaderWithBtn as Component],
  ['CDateHeader', CDateHeader as Component],
  ['CNothing', CNothing as Component],
  ['CTypeTab', CTypeTab as Component],
  ['CNoData', CNoData as Component]
])
const registerComponent: Plugin = (app: App) => {
  //map循环，回调函数中第一个参数是value，第二个参数是key
  components.forEach((component, name) => app.component(name, component))
}

export default registerComponent
