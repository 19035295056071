import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/img/title-icon.png';
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-fb13ce9e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "c-title"
};
var _hoisted_2 = {
  key: 0,
  src: _imports_0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.showIcon ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(_ctx.title), 1), _renderSlot(_ctx.$slots, "info", {}, undefined, true), _ctx.showJump ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "checkDevice ml-auto",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    function () {
      return _ctx.jumpTo && _ctx.jumpTo.apply(_ctx, arguments);
    })
  }, _toDisplayString(_ctx.jumpText), 1)) : _createCommentVNode("", true)]);
}