import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { globalRegister } from './global';
import localCache from '@/utils/localCache';
import { ImagePreview } from 'vant';

import '@/assets/css/custom.less';
const app = createApp(App);

app.use(store);
app.use(router);

app.use(globalRegister);

app.mount('#app');
// app.use(PullRefresh);
// app.use(Tag);
// app.use(Uploader);
app.use(ImagePreview);
// app.use(Empty);
// app.use(Step);
// app.use(Steps);
// app.use(Loading);

if (localCache.getItem('curSiderbarIndex')) {
  const curSiderbarIndex = Number(localCache.getItem('curSiderbarIndex'));
  // const siderbar = JSON.parse(localCache.getItem('siderbar'))

  store.commit('production/updateSiderbarIndex', curSiderbarIndex);
  // store.commit('production/updateSiderbar', siderbar)
}
if (localCache.getItem('siderbar')) {
  const siderbar = JSON.parse(localCache.getItem('siderbar'));
  store.commit('production/updateSiderbar', siderbar);
}
if (localCache.getItem('curSiderbarId')) {
  const curSiderbarId = localCache.getItem('curSiderbarId');
  store.commit('production/updateSiderbarId', curSiderbarId);
}
if (localCache.getItem('vibrationCurSiderbarIndex')) {
  const curSiderbarIndex = Number(
    localCache.getItem('vibrationCurSiderbarIndex')
  );
  // const siderbar = JSON.parse(localCache.getItem('siderbar'))

  store.commit('vibrationEquipment/updateSiderbarIndex', curSiderbarIndex);
  // store.commit('production/updateSiderbar', siderbar)
}
if (localCache.getItem('vibrationSiderbar')) {
  const siderbar = JSON.parse(localCache.getItem('vibrationSiderbar'));
  store.commit('vibrationEquipment/updateSiderbar', siderbar);
}
if (localCache.getItem('vibrationCurSiderbarId')) {
  const curSiderbarId = localCache.getItem('vibrationCurSiderbarId');
  store.commit('vibrationEquipment/updateSiderbarId', curSiderbarId);
}
if (localCache.getItem('useList')) {
  const useList = JSON.parse(localCache.getItem('useList'));
  store.commit('production/updateUseList', useList);
}
if (localCache.getItem('drawList')) {
  const drawList = JSON.parse(localCache.getItem('drawList'));
  store.commit('production/updateDrawList', drawList);
}

//为了是element-plus的date-picker的周第一天为周六  ----start weekStart改为6
import dayjs from 'dayjs';
import zhCn from 'dayjs/locale/zh-cn';
zhCn.weekStart = 7; // 蓝天周六到周五是一周，weekStart改为6  标准版周日到周六是一周，weekStart改为7
dayjs.locale(zhCn);
//为了是element-plus的date-picker的周第一天为周六  ----end

router.beforeEach((to, from) => {
  document.title = to.meta.title as string;
  // console.log('router', to);
  if (to.query.token) {
    localCache.setItem('token', to.query.token);
  }
  if (to.query.factoryId) {
    localCache.setItem('factoryId', to.query.factoryId);
  }
  return true;
});
