import {
  IDetailListItem,
  IDeviceGroupListItemNew,
  IFacDataItem
} from '@/service/production/equipmentLive/type'
import { Module } from 'vuex'
import { IRootState, IProductionModuleState } from '../type'
import localCache from '@/utils/localCache'

const productionModule: Module<IProductionModuleState, IRootState> = {
  namespaced: true,
  state: () => {
    return {
      drawList: [],
      useList: [],
      deviceGroup: [], // 当前选中菜单下的设备列表
      siderbar: [], // 当前菜单列表
      curSiderbarIndex: 0, // 当前选中菜单项的索引
      curSiderbarId: undefined // 当前选中菜单项的groupNo
    }
  },
  getters: {
    deviceGroupId(state) {
      return state.curSiderbarId
    },
    deviceGroupList(state) {
      return state.deviceGroup //state.deviceGroup[state.curSiderbarIndex]?.groupList ?? []
    }
  },
  mutations: {
    updateDeviceGroup(state, list: IFacDataItem[]) {
      state.deviceGroup = list
    },
    updateSiderbar(state, list: IDeviceGroupListItemNew[]) {
      state.siderbar = list
      localCache.setItem('siderbar', JSON.stringify(list))
    },
    updateSiderbarIndex(state, index: number) {
      state.curSiderbarIndex = index
      localCache.setItem('curSiderbarIndex', index)
    },
    updateSiderbarId(state, Id: string | undefined) {
      state.curSiderbarId = Id
      localCache.setItem('curSiderbarId', Id)
    },
    updateDrawList(state, list: IDetailListItem[]) {
      state.drawList = list
      localCache.setItem('drawList', JSON.stringify(list))
    },
    updateUseList(state, list: IDetailListItem[]) {
      state.useList = list
      localCache.setItem('useList', JSON.stringify(list))
    }
  },
  actions: {}
}

export default productionModule
