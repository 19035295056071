import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useToBack } from '@/hooks/fn';
export default defineComponent({
  name: 'c-page-header',
  components: {},
  props: {},
  setup: function setup(props, ctx) {
    var toBack = useToBack();
    var route = useRoute();
    var title = route.meta.title;
    return {
      toBack: toBack,
      title: title
    };
  }
});