import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0fe65dd4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "typeTab"
};
var _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeTabs, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: item,
      class: _normalizeClass([{
        typeTabActive: index === _ctx.curType
      }, "typeTabItem"]),
      onClick: function onClick($event) {
        return _ctx.typeTabClick(index);
      }
    }, _toDisplayString(item), 11, _hoisted_2);
  }), 128))]);
}