import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-12e2bc52"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "column"
};
var _hoisted_2 = {
  class: "info flex align-center justify-between"
};
var _hoisted_3 = {
  class: "name"
};
var _hoisted_4 = {
  class: "desc"
};
var _hoisted_5 = {
  class: "chart"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.warehouse_name), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.item.stone_stock) + "吨", 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
    class: "actualColumn",
    style: _normalizeStyle({
      width: _ctx.item.stone_stock / _ctx.item.max_stone_stock * 100 + '%'
    })
  }, null, 4)])]);
}