import { App } from 'vue'
import { formatUtcString } from '@/utils/data-format'
import parseTime from '../hooks/parseTime'
export default function registerProperties(app: App): void {
  app.config.globalProperties.$filter = {
    formatUtcTime(utcString: string, format = 'YYYY-MM-DD HH:mm:ss') {
      return formatUtcString(utcString, format)
    }
  }
  app.config.globalProperties.$emptyStringFormat = (
    val: string | number | null
  ) => {
    return val || val == '0' ? val : '--'
  }
  app.config.globalProperties.$parseTime = parseTime
}
