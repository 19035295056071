import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-04112071"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "c-date-select"
};
var _hoisted_2 = {
  class: "dateSelect flex align-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_icon = _resolveComponent("van-icon");
  var _component_van_calendar = _resolveComponent("van-calendar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
    class: "left",
    style: _normalizeStyle({
      backgroundColor: _ctx.backgroundColor
    }),
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    function () {
      return _ctx.preClick && _ctx.preClick.apply(_ctx, arguments);
    })
  }, [_createVNode(_component_van_icon, {
    name: "arrow-left",
    size: "18",
    color: _ctx.arrowColor
  }, null, 8, ["color"])], 4), _createElementVNode("div", {
    class: "content",
    style: _normalizeStyle({
      backgroundColor: _ctx.backgroundColor,
      color: _ctx.textColor
    }),
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    function () {
      return _ctx.showCalendar && _ctx.showCalendar.apply(_ctx, arguments);
    })
  }, _toDisplayString(_ctx.date), 5), _createElementVNode("div", {
    class: "right",
    style: _normalizeStyle({
      backgroundColor: _ctx.backgroundColor
    }),
    onClick: _cache[2] || (_cache[2] =
    //@ts-ignore
    function () {
      return _ctx.nextClick && _ctx.nextClick.apply(_ctx, arguments);
    })
  }, [_createVNode(_component_van_icon, {
    name: "arrow",
    size: "18",
    color: _ctx.arrowColor
  }, null, 8, ["color"])], 4)]), _ctx.show ? (_openBlock(), _createBlock(_component_van_calendar, {
    key: 0,
    color: "#4372F6",
    ref: "calendarRef",
    show: _ctx.show,
    "onUpdate:show": _cache[3] || (_cache[3] = function ($event) {
      return _ctx.show = $event;
    }),
    "max-date": _ctx.maxDate,
    "min-date": _ctx.minDate,
    "default-date": new Date(_ctx.date),
    onConfirm: _ctx.confirmDate
  }, null, 8, ["show", "max-date", "min-date", "default-date", "onConfirm"])) : _createCommentVNode("", true)]);
}