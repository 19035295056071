import { App } from 'vue';
import 'vant/lib/index.css'; // 全局引入样式
import {
  Button,
  Calendar,
  Icon,
  NavBar,
  Image as VanImage,
  Grid,
  GridItem,
  DatetimePicker,
  Popup,
  Field,
  Form,
  CellGroup,
  Collapse,
  CollapseItem,
  Col,
  Row,
  Picker,
  List,
  Tab,
  Tabs,
  IndexBar,
  Cell,
  IndexAnchor,
  Search,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Stepper,
  Progress,
  PullRefresh,
  Tag,
  Uploader,
  Empty,
  Step,
  Steps,
  Loading,
  Overlay
} from 'vant';

export default function registerElementPlus(app: App): void {
  const components = [
    Button,
    NavBar,
    Icon,
    Calendar,
    VanImage,
    Grid,
    GridItem,
    DatetimePicker,
    Popup,
    Field,
    Form,
    CellGroup,
    Col,
    Row,
    Picker,
    List,
    Tabs,
    Tab,
    IndexBar,
    IndexAnchor,
    Cell,
    Search,
    Collapse,
    CollapseItem,
    Checkbox,
    CheckboxGroup,
    RadioGroup,
    Radio,
    Stepper,
    Progress,
    Tag,
    PullRefresh,
    Uploader,
    Empty,
    Step,
    Steps,
    Loading,
    Overlay
  ];
  for (const component of components) {
    app.component(component.name, component);
  }
}
