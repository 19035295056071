import "core-js/modules/es.number.constructor.js";
import { defineComponent, ref } from 'vue';
import dayjs from 'dayjs';
import weekYear from 'dayjs/plugin/weekYear';
dayjs.extend(weekYear);
export default defineComponent({
  name: 'CDateHeader',
  props: {
    type: {
      type: Number,
      default: 1 //0日报  1周  2月
    },
    title: {
      type: String,
      default: '报表周期'
    },
    disabledDate: {
      type: String,
      default: 'disabledFuture'
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  emits: ['confirm-date'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var formatDate = function formatDate(date) {
      return dayjs(date).format('YYYY/MM/DD');
    };
    var date = ref(new Date());
    var confirmDate = function confirmDate(value) {
      if (props.type === 1) {
        // emit('confirm-date', dayjs(value).add(1, 'day').format('YYYY/MM/DD'))
        emit('confirm-date', value);
      } else {
        emit('confirm-date', value);
      }
    };
    var disabledDateApi = function disabledDateApi(date) {
      if (props.disabledDate === 'disabledFuture') {
        if (dayjs(date).isBefore(dayjs())) return false;
        return true;
      } else {
        if (dayjs(date).isBefore(dayjs())) return true;
        return false;
      }
    };
    return {
      date: date,
      confirmDate: confirmDate,
      disabledDateApi: disabledDateApi
    };
  }
});