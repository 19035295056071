import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { defineComponent, ref, inject } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
  name: 'CDateTimeRangeSelect',
  emits: ['confirm-date'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var defaultDate = inject('defaultDate');
    var show = ref(false);
    var startTime = ref(dayjs().format('YYYY/MM/DD'));
    var endTime = ref(dayjs().format('YYYY/MM/DD'));
    var defaultTime = ref(new Date());
    var dateType = ref('start');
    var dateClick = function dateClick(type) {
      dateType.value = type;
      if (type === 'start') {
        defaultTime.value = new Date(startTime.value);
      } else {
        defaultTime.value = new Date(endTime.value);
      }
      show.value = true;
    };
    var formatDate = function formatDate(date) {
      return dayjs(date).format('YYYY/MM/DD');
    };
    if (defaultDate !== null && defaultDate !== void 0 && defaultDate.value) {
      startTime.value = formatDate(new Date(defaultDate.value));
      endTime.value = formatDate(new Date(defaultDate.value));
    }
    var confirmDate = function confirmDate(value) {
      if (dateType.value === 'start') {
        startTime.value = formatDate(value);
      }
      if (dateType.value === 'end') {
        if (dayjs(value).isBefore(dayjs(startTime.value))) {
          _Toast('结束时间不能大于开始时间');
          show.value = false;
          return;
        }
        endTime.value = formatDate(value);
      }
      show.value = false;
    };
    var maxDate = ref(new Date());
    var minDate = ref(new Date(dayjs().subtract(7, 'year').format('YYYY/MM/DD')));
    var confirm = function confirm() {
      var payload = {
        startTime: startTime.value,
        endTime: endTime.value
      };
      emit('confirm-date', payload);
    };
    return {
      show: show,
      startTime: startTime,
      endTime: endTime,
      minDate: minDate,
      maxDate: maxDate,
      dateClick: dateClick,
      defaultTime: defaultTime,
      confirmDate: confirmDate,
      confirm: confirm
    };
  }
});